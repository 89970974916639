<template>
  <section id="moly-ingreso-analito">
    <b-row>
      <b-col>
        <BrunaForm ref="analitosRef" :fields="this.analitos.fields" :card="this.analitos.card">
          <template v-slot:footer>
            <b-button variant="primary" class="mt-4 float-right" @click="addNewAnalitod">Agregar</b-button>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ag-grid-table ref="grid-list-analitos" :configUrl="config" :dataUrl="data" :editUrl="edit"
          rowSelection="single">
        </ag-grid-table>

      </b-col>
    </b-row>

  </section>
</template>
<script>
import useApiServices from '@/services/useApiServices.js';
import {
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
// import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import NumberCellEditor from "@/views/brunacomponents/ag-grid-table/cell-editors/NumberCellEditor"
import router from '@/router'
import EliminarActions from "./actions/EliminarActions.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BrunaForm,
    AgGridTable,
    ToastificationContent,
    EliminarActions: EliminarActions,
    NumberCellEditor,

  },
  created() {
  },
  data() {
    return {
      config: useApiServices.analitosConfig,
      data: useApiServices.analitosData,
      edit: useApiServices.analitosEdit,
      itemsData: [],
      formatData: {

        selected: false,
        selectBrunaTable: null,
        selectQadTable: null,

      },

      analitos: {
        card: {
          title: "Agregar calidades analitos",
          subtitle: "",
          tooltip: "",
          sidebarContent: {
            title: 'Calidades analitos',
            body: "<p><strong>Agregar calidades analitos:</strong> En esta secci&oacute;n usted podr&aacute; subir valores uno a uno de sus calidades(leyes) donde deber& aacute; llenar cada una de las celdas solicitadas.En caso que no existan nuevos valores para la mineralog& iacute; a, por favor dejar en valor 0.</p><p><strong>Hist&oacute;rico calidades analitos:</strong> En la tabla usted visualizar&aacute; los datos que ha ido cargando uno a uno. En caso de algún error puede dar doble click sobre el valor para modificaciones o seleccionar el bot&oacute;n eliminar para quitar el registro.</p><p>Puede filtrar sus datos donde aparece el &iacute;cono de filtros.</p>"
          }
  },
  fields: [
    {
      label: "Procedencia",
      type: "select",
      id: 'codigo_procedencia',
      rules: 'required',
      options: [
        { value: null, text: "Selecciona una opcion" },
        { value: 'ANT', text: "ANT" },
        { value: 'CJ', text: "CJ" },
        { value: 'COL', text: "COL" },
        { value: 'DP', text: "DP" },
        { value: 'MCO', text: "MCO" },
        { value: 'MSA', text: "MSA" },
        { value: 'MTE', text: "MTE" },
      ],
      format: {
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3
      },
      value: null
    },
    {
      label: "Fecha",
      type: "datepicker",
      id: 'fecha',
      rules: 'required',
      format: {
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3
      },
      value: null,
      min:'2020-08-01',
      max:'2021-12-31',
    },
    {
      label: "Molibdeno (Mo%)",
      type: "input",
      id: 'mo',
      rules: 'required|numeric2',
      format: {
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3
      },
      value: null,
      show: true
    },
    {
      label: "Cobre (Cu%)",
      type: "input",
      id: 'cu',
      rules: 'required|numeric2',
      format: {
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3
      },
      value: null,
      show: true
    },
    {
      label: "Hierro (Fe%)",
      type: "input",
      id: 'fe',
      rules: 'required|numeric2',
      format: {
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3
      },
      value: null,
      show: true
    },
    {
      label: "Wolframio (W%)",
      type: "input",
      id: 'w',
      rules: 'required|numeric2',
      format: {
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3
      },
      value: null,
      show: true
    },
    {
      label: "Calcio (Ca%)",
      type: "input",
      id: 'ca',
      rules: 'required|numeric2',
      format: {
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3
      },
      value: null,
      show: true
    },
    {
      label: "Silicio (Si%)",
      type: "input",
      id: 'si',
      rules: 'required|numeric2',
      format: {
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3
      },
      value: null,
      show: true
    },
    {
      label: "Humedad (H2O%)",
      type: "input",
      id: 'h2o',
      rules: 'required|numeric2',
      format: {
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3
      },
      value: null,
      show: true
    },
    {
      label: "Mineralogia",
      type: "input",
      id: 'mineralogia',
      rules: 'required|numeric2',
      format: {
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3
      },
      value: null
    },
  ]
},

    };
  },
methods: {

  addNewAnalitod(event) {
    let data = this.analitos.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
    // console.log(data);
    Promise.all([
      this.$refs.analitosRef.validate(),
    ]).then(([analitosValid]) => {
      if (analitosValid) {
        useApiServices.postAnalitosCreate(data)
          .then((response) => {
            // console.log(response);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Carga Exitosa`,
                icon: "UploadIcon",
                variant: "success",
                html: `Se ha guardado el nuevo registro exitosamente.`,
              },
            });
            this.$refs['grid-list-analitos'].refreshRows()
          })
          .catch((error) => {
            let message = error.response.data.message;

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Ocurrio un Error`,
                icon: "UploadIcon",
                variant: "danger",
                html: message,
              },
            });

          });
      }
    });


  },

  findFieldByName(fields, name) {
    return fields.find(field => field.id == name)
  },

},
mounted() {

},

};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>